import React from 'react';
//import { useNavigate } from 'react-router-dom';

function ProjectItem({ id, image, projectName, enabled, link }) {
	//const navigate = useNavigate();
  return (
	<div className={enabled ? "projectItem" : "projectItem disabled"} onClick={() => {
		if (enabled && link)
		{
			// navigate("/project/" + id)
			window.open(link);
		}
	}}>
		<div style={{backgroundImage: `url(${image})`}} className='bgImage' />
		<h2>{projectName}</h2>
	</div>
  )
}

export default ProjectItem