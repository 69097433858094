import React from 'react'
import { useNavigate } from 'react-router-dom';
import "../styles/components/NextPageBanner.css";

function NextPageBanner({ to }) {
	const navigate = useNavigate();

	if (to === undefined)
	{
		to = "/";
	}

	const changeRoute = () => {
		navigate(to);
		window.scrollTo(0, 0);
	};

	return (
		<div className='nextPageBanner' onClick={changeRoute}><button className='nextPageBtn'>Next</button></div>
	)
}

export default NextPageBanner