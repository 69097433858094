import React from 'react'
import "../styles/components/Label.css";

function Label({ text }) {
  return (
	<div className='label'>
		<span>{text}</span>
	</div>
  )
}

export default Label