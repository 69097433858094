import React from 'react'
import "../styles/contact.css";
import PhoneIcon from "@material-ui/icons/PhoneEnabled";
import LocationIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";

function Contact() {
  return (
	<div className='contact'>
		<h3 id='title'>Contact Me and Let's Work Together</h3>

		<div className='contact-container'>
			<div className='contact-option'>
				<PhoneIcon className='contact-icons' />
				<h4>+44 75724 10598</h4>
			</div>
			<div className='contact-option'>
				<EmailIcon className='contact-icons' />
				<h4><a href='mailto:samburford@protonmail.com'>samburford@protonmail.com</a></h4>
			</div>
			<div className='contact-option'>
				<LocationIcon className='contact-icons' />
				<h4>Camberley, Surrey, United Kingdom</h4>
			</div>
		</div>
	</div>
  )
}

export default Contact