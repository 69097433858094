import React from 'react'
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";

function SocialLinks() {
  return (
	<div>
		<a target='_blank' rel='noreferrer' href="https://www.linkedin.com/in/sam-burford/"><LinkedInIcon /></a>
		<a target='_blank' rel='noreferrer' href="mailto:samburford@protonmail.com"><EmailIcon /></a>
		<a target='_blank' rel='noreferrer' href="https://github.com/sam-burford"><GitHubIcon /></a>
	</div>
  )
}

export default SocialLinks