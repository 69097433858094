import React from 'react'
import "../styles/Footer.css";
import SocialLinks from './SocialLinks';

function Footer() {
  return (
	<div className='footer'>
		<div className='socialMedia'>
			<SocialLinks />
		</div>
		<p>&copy; 2023 sam-burford.com</p>
	</div>
  )
}

export default Footer