import React from 'react';
import "../styles/Projects.css";
import ProjectItem from '../components/ProjectItem';
import { projectList} from "../helpers/ProjectList";
import NextPageBanner from '../components/NextPageBanner';

function Projects() {
  return (
    <div>
      <div className='projects'>
        <h1>My Projects</h1>
        <div className='projectList'>
          {projectList.map((project, index) => {
            return <ProjectItem id={index} projectName={project.name} image={project.image} enabled={project.enabled} link={project.githubLink} />;
          })}
        </div>
      </div>
      <NextPageBanner to="/contact" />
    </div>
  )
}

export default Projects