import React from 'react'
import NextPageBanner from '../components/NextPageBanner';

import "../styles/About.css";

function About() {
  return (
	<div className='about-page'>
		<h3 id='title'>About Me</h3>

		<div className='container-about'>
			<p className='aboutParagraph'>
				Hello! I'm Sam Burford 🙋‍♂️, a web developer based in Camberley, Surrey, United Kingdom. <br /><br />
				With a background in the pharmaceutical 💊 industry, I'm now on an exciting journey of 
				transition and transformation. My career shift into web development is driven by an
				 insatiable passion for creating and learning. 
			</p>
			<hr />

			<h4 className='aboutHeading'>My Path</h4>
			<p className='aboutParagraph'>
				Embracing coding and web development, I've dived headfirst into the world of technology. 
				My journey has led me to build dynamic, user-friendly, and responsive web applications. 
				From .NET Core to Angular.js, I'm constantly exploring the latest tools and languages. 
			</p>
		</div>
		<NextPageBanner to="/experience" />
	</div>
  )
}

export default About