import React from 'react'
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";

function NavBarPageLink({ to, children }) {
	const location = useLocation();

	return (
		<Link to={to} className={location.pathname === to ? "active" : ""} >{children}</Link>
	)
}

export default NavBarPageLink