import React, { useEffect, useState } from 'react'
import "../styles/components/WhoIamText.css";

const options = [
	"a Web Developer", 
	"a Front-End Designer", 
	"a Full-Stack Developer", 
	"a UI/UX Designer", 
	"a Game Developer", 
  "a Software Developer", 
];

let currentTextIndex = undefined;

function getRandomText()
{
	let randomIndex = undefined;

	while (randomIndex === undefined || randomIndex === currentTextIndex)
	{
		randomIndex = Math.floor(Math.random() * options.length);
	}

	currentTextIndex = randomIndex;
	return options[currentTextIndex];
}

function WhoIAmText() {
  const [displayText, setDisplayText] = useState(getRandomText());
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsHidden(true); // Start the fade-out animation. 
      setTimeout(() => {
        const newText = getRandomText();
        setDisplayText(newText);
        setIsHidden(false); // Start the fade-in animation with the new text. 
      }, 500); // After 500ms (half of the transition duration). 
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      <span className={`fade-in-out ${isHidden ? 'hidden' : ''}`}>{displayText}</span>
    </div>
  );
}

export default WhoIAmText