import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import "../styles/Navbar.css";
import ReorderIcon from "@material-ui/icons/Reorder";
import NavBarPageLink from './NavBarPageLink';

function Navbar() {
	const [expandNavbar, setExpandNavbar] = useState(false);

	const location = useLocation();

	// Runs this function whenever the location variable changes. 
	useEffect(() => {
		setExpandNavbar(false)
	}, [location])

  return (
	<div className='navbar' id={expandNavbar ? "open" : "close"}>
		<div className='toggleButton'>
			<button onClick={() => {setExpandNavbar((prev) => !prev)}}> <ReorderIcon /> </button>
		</div>
		<div className='links'>
			<NavBarPageLink to="/">Home</NavBarPageLink>
			<NavBarPageLink to="/about">About Me</NavBarPageLink>
			<NavBarPageLink to="/experience">Experience</NavBarPageLink>
			<NavBarPageLink to="/projects">Projects</NavBarPageLink>
			<NavBarPageLink to="/contact">Contact</NavBarPageLink>
		</div>
	</div>
  )
}

export default Navbar