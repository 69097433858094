import React from 'react'
/* Components */
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import Label from '../components/Label';
import NextPageBanner from '../components/NextPageBanner';
/* Styles */
import "react-vertical-timeline-component/style.min.css";
import "../styles/Experience.css";
/* Icons */
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import CertificateIcon from "@material-ui/icons/CardMembership";

function Experience() {
  return (
	<div className='experience'>
    <h3 id='experienceTitle'>My Journey</h3>
    <VerticalTimeline lineColor='#FFF7'>
      <VerticalTimelineElement 
        className='vertical-timeline-element'
        date='Oct 19, 2023'
        iconStyle={{background: "#eacb19", color: "#101010"}}
        icon={<CertificateIcon />}
      >
        <h3 className='vertical-timeline-element-title'>Building Applications with Angular, ASP.NET Core, and Entity Framework Core</h3>
        <h4 className='vertical-timeline-element-subtitle'><a target='_blank' rel='noreferrer' href='https://www.linkedin.com/learning/certificates/d23f4acfa8333bace3e89febe8a66d61587af6b48837725820cdad37ff2b310a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3BKr9rq64LQ8KKBiFWxXeODQ%3D%3D'>Certificate</a></h4>
      </VerticalTimelineElement>
      <VerticalTimelineElement 
        className='vertical-timeline-element--education'
        date='2022-2023'
        iconStyle={{background: "#53aafc", color: "#202020"}}
        icon={<WorkIcon />}
      >
        <Label text="Node.js" />
        <Label text="AWS" />
        <Label text="MySQL" />
        <Label text="Docker" />
        <h3 className='vertical-timeline-element-title'>Full-Stack Web Developer</h3>
        <h4 className='vertical-timeline-element-subtitle'>Camberley, Surrey, UK</h4>
        <h5>Vertex Digital Solutions Ltd</h5>
        <p>
          With extensive experience in web development and design, I founded 
          Vertex Digital Solutions Ltd and took charge of the full-stack development 
          of our debut project, Pharmacy Hub. Guiding its entire lifecycle, I 
          conceptualised, planned, and executed both frontend and backend using HTML5, 
          CSS3, JavaScript, and Node.js. Leveraging plugins like jQuery and Bootstrap, 
          I expedited development. Agile project management via Git and GitHub 
          facilitated collaboration with other developers, while expertise in SQL, MySQL, AWS, Docker, and 
          Linux maintained industry standards and efficiency. 
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement 
        className='vertical-timeline-element--work'
        date='2018-2020'
        iconStyle={{background: "#53aafc", color: "#202020"}}
        icon={<WorkIcon />}
      >
        <Label text="WordPress" />
        <Label text="HTML" />
        <Label text="CSS" />
        <Label text="JavaScript" />
        <h3 className='vertical-timeline-element-title'>WordPress Developer</h3>
        <h4 className='vertical-timeline-element-subtitle'>Self-employed - Farnborough, Hants, UK</h4>
        <p>
          As a developer, I have designed custom WordPress websites for a photography startup. 
          My priority has been to create responsive designs that meet the client's requirements. 
          My work has showcased my adaptability and critical thinking skills by effectively integrating innovative WordPress plugins, such as Jetpack. 
          I believe in building strong relationships with clients through clear communication and efficient time management. 
          My expertise in WordPress customisation and responsive web design has helped me deliver a superior user experience consistently. 
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement 
        className='vertical-timeline-element--education'
        date='2017-2019'
        iconStyle={{background: "#1ad254", color: "#202020"}}
        icon={<SchoolIcon />}
      >
        <Label text="Java" />
        <Label text="Python" />
        <Label text="HTML" />
        <Label text="CSS" />
        <Label text="JavaScript" />
        <h3 className='vertical-timeline-element-title'>Sixth Form College Farnborough</h3>
        <h4 className='vertical-timeline-element-subtitle'>Farnborough, Hants, UK</h4>
        <h5>A-Level Computer Science, Information Technology, Business Studies</h5>
        <p>
          During my time at The Sixth Form College Farnborough, I focused on Java, 
          web development with HTML, JavaScript, and Bootstrap. I also explored 
          various programming paradigms and gained experience in agile project 
          workflows, emphasizing collaboration and efficiency. These studies laid 
          a solid foundation for my career in technology and development. 
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
    <NextPageBanner to="/projects" />
  </div>
  )
}

export default Experience