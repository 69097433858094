import ClipCraftThumbnail from "../assets/projects/Clip_Craft_Thumbnail.svg";
import NimbusThumbnail from "../assets/projects/Nimbus_Weather_App.svg";
import PharmacyHubThumnail from "../assets/projects/Pharmacy_Hub_Logo.png";
import QuizzeyThumbnail from "../assets/projects/Quizzey_Logo.png";

export const projectList = [
	{
		name: "Pharmacy Hub", 
		image: PharmacyHubThumnail, 
		imageAlt: "Pharmacy Hub - written in a clean and elegant font", 
		githubLink: "https://pharmacyhub.net/", 
		skills: "stuff", 
		enabled: true, 
		featued: true
	}, 
	{
		name: "Nimbus - Weather App", 
		image: NimbusThumbnail, 
		imageAlt: "", 
		githubLink: "https://github.com/sam-burford/Nimbus_Weather_App", 
		skills: "", 
		enabled: true
	}, 
	{
		name: "Quizzey", 
		image: QuizzeyThumbnail, 
		imageAlt: "Pharmacy Hub - written in a clean and elegant font", 
		githubLink: "https://github.com/sam-burford/Quizzey", 
		skills: "stuff", 
		enabled: true, 
		featued: true
	}, 
	{
		name: "ClipCraft - Video Editor", 
		image: ClipCraftThumbnail, 
		imageAlt: "Clip Craft - Video Editor Logo with a video camera", 
		githubLink: "", 
		skills: "React.js", 
		enabled: false
	}, 
];